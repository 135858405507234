.conce {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: Arial, Helvetica, sans-serif; }
  .conce__title {
    margin-bottom: 0.5rem; }
  .conce__radio-options {
    background: #F9FAFB;
    border-radius: 0.5rem; }
  .conce__radio-option {
    position: relative;
    cursor: pointer;
    border: 1px solid #F3F4F6;
    padding: 1rem; }

.conce__radio-option {
  position: relative;
  cursor: pointer;
  border: 1px solid #F3F4F6;
  padding: 1rem; }
  .conce__radio-option__head {
    display: flex; }
  .conce__radio-option__body {
    cursor: default; }
  .conce__radio-option__input {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0 0 2px #374151; }
  .conce__radio-option__input:checked {
    box-shadow: 0 0 0 2px #374151; }
  .conce__radio-option__input:checked:before {
    background: #374151; }
  .conce__radio-option__input:before {
    content: '';
    display: block;
    width: 70%;
    height: 70%;
    margin: 15% auto;
    border-radius: 50%; }
  .conce__radio-option__label {
    display: flex;
    flex-direction: column;
    margin-left: 0.75rem;
    cursor: pointer;
    margin-top: -1px; }

.conce__radio-option:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem; }

.conce__radio-option:last-child {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem; }

.conce__stripe {
  width: 100%; }
  .conce__stripe--error {
    color: #EF4444;
    font-size: 0.85rem;
    line-height: 20px;
    margin-top: 0.5rem; }
  .conce__stripe input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box; }
  .conce__stripe__button {
    margin-top: 1rem;
    background: #10B981;
    color: #ffffff;
    border-radius: 0.25rem;
    border: 0;
    padding: 12px 16px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%; }
  .conce__stripe__button:hover {
    filter: contrast(115%); }
  .conce__stripe__button:disabled {
    background: #9CA3AF;
    cursor: not-allowed; }
  .conce__stripe__button .conce__icon {
    height: 1rem;
    width: 1rem;
    float: right; }
  .conce__stripe__spinner, .conce__stripe__spinner:before, .conce__stripe__spinner:after {
    border-radius: 50%; }
  .conce__stripe__spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0); }
  .conce__stripe__spinner:before, .conce__stripe__spinner:after {
    position: absolute;
    content: ""; }
  .conce__stripe__spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #6B7280;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s; }
  .conce__stripe__spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #9CA3AF;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease; }

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  @media only screen and (max-width: 600px) {
    .conce__stripe form {
      width: 80vw; } }

.conce__stripe-element {
  border-radius: 0.25rem;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box; }
